import React from 'react';
import './Footer.css';
import { FaXTwitter } from "react-icons/fa6";
import { FaInstagram, FaFacebookF } from "react-icons/fa";

function Footer() {
  const scrollToElement = (id) => {
    const element = document.querySelector(id);
    window.scrollTo({
      top: element.offsetTop - 80,
      behavior: "smooth"
    });
  }

  return (
    <div className='footer-container'>
      <section className='social-media'>
        <div className='social-media-wrap'>
          <div className='footer-logo'>
            <div onClick={() => scrollToElement('#home')} className='social-logo'>
              <img src="/images/nav-brand.png" alt="brand logo" />
            </div>
          </div>
          <p className='website-rights'>TropicoPainting © 2024</p>
          <div className='social-icons'>
            {/* <div>
              <FaFacebookF color='white' size={25} />
            </div>
            <div>
              <FaXTwitter color='white' size={25} />
            </div>
            <div>
              <FaInstagram color='white' size={25} />
            </div> */}
          </div>
        </div>
      </section>
    </div>
  );
}

export default Footer;
