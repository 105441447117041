import React from 'react';
import './Cards.css';
import CardItem from './CardItem';
import { FaListCheck } from "react-icons/fa6";
import { FaRegThumbsUp } from "react-icons/fa";
import { RiMoneyDollarBoxLine } from "react-icons/ri";
import { MdOutlineFormatPaint } from "react-icons/md";
import { AiFillSafetyCertificate } from "react-icons/ai";

const ICON_COLOR = '#6A5ACD'

function Cards() {
  return (
    <div id="services" className='cards'>
      <h1>What We Offer</h1>
      <div className='cards__container'>
        <div className='cards__wrapper'>
          <ul className='cards__items'>
            <CardItem
              logo={<MdOutlineFormatPaint size={100} color={ICON_COLOR} />}
              title='Services Offered'
            >
              <div className="service-category">
                <ul>
                  <li>Industrial Painting</li>
                  <li>Commercial Painting</li>
                  <li>Residential Painting</li>
                  <li>Ceiling (Corrugated or Flat)</li>
                  <li>Wallpaper Removal</li>
                </ul>
              </div>
            </CardItem>
            <CardItem
              logo={<RiMoneyDollarBoxLine size={100} color={ICON_COLOR} />}
              title='Free Estimates'
              text='With free estimates, we give you a clear view of what your house or office painting entails and the associated cost.'
            />
            <CardItem
              logo={<FaListCheck size={100} color={ICON_COLOR} />}
              title='Full Prep Work'
              text='Preparing an area for painting is just as important as the painting itself. We take every precaution to protect your space.'
            />
            <CardItem
              logo={<AiFillSafetyCertificate size={100} color={ICON_COLOR} />}
              title='We Specialize In'
            >
              <div className="service-category">
                <ul>
                  <li>Painting and Spraying</li>
                  <li>Ceiling Texture (Dex)</li>
                  <li>Exterior and Interior Brick Painting</li>
                  <li>Deck Staining and Power Washing</li>
                  <li>Skimming (Plastering)</li>
                </ul>
              </div>
            </CardItem>
          </ul>
        </div>
      </div>
    </div>
  );
}

export default Cards;
