import React, { useState } from 'react';
import './Quote.css';
const WORK_EMAIL = 'tropicopainting@gmail.com'

function Quote() {
  const [email, setEmail] = useState('');
  const [subject, setSubject] = useState('');
  const [message, setMessage] = useState('');

  const handleSubmit = (e) => {
    e.preventDefault();
    const mailtoLink = `mailto:${WORK_EMAIL}?subject=${encodeURIComponent(
      subject
    )}&body=${encodeURIComponent(message)} %0D%0A%0D%0A From: ${email}`;
    window.location.href = mailtoLink;
  };

  return (
    <div id="quote" className="email-form">
      <h1>Request a Quote</h1>
      <form onSubmit={handleSubmit}>
        <div className="form-group">
          <label htmlFor="email">Email</label>
          <input
            type="email"
            id="email"
            placeholder="Enter your email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
          />
        </div>
        <div className="form-group">
          <label htmlFor="subject">Subject</label>
          <input
            type="text"
            id="subject"
            placeholder="Enter the subject"
            value={subject}
            onChange={(e) => setSubject(e.target.value)}
            required
          />
        </div>
        <div className="form-group">
          <label htmlFor="message">Message</label>
          <textarea
            id="message"
            placeholder="Enter your message"
            value={message}
            onChange={(e) => setMessage(e.target.value)}
            rows="4"
            required
          />
        </div>
        <button type="submit" className='btn btn--outline quote-submit-button'>Get a Quote</button>
      </form>
    </div>
  );
}

export default Quote;
