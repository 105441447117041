import React from 'react'
import './Gallery.css';

const GalleryItem = (props) => {
  
  return (
    <div className="grid-item">
      <img className='grid-image' src={props.href} alt="Our Painted Work." />
    </div>
  )
}

export default GalleryItem
