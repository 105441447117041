import React, { useState, useEffect } from 'react';
import { Button } from './Button';
import './Navbar.css';
import { IoMenuSharp, IoCloseSharp } from "react-icons/io5";

function Navbar() {
  const [click, setClick] = useState(false);
  const [button, setButton] = useState(true);

  const handleClick = () => setClick(clickVal => !clickVal);
  const closeMobileMenu = () => setClick(false);

  const showButton = () => {
    if (window.innerWidth <= 960) {
      setButton(false);
    } else {
      setButton(true);
    }
  };

  const scrollToElement = (id) => {
    const element = document.querySelector(id);
    window.scrollTo({
      top: element.offsetTop - 80,
      behavior: "smooth"
    });
  }

  const onLinkClick = (id) => {
    closeMobileMenu()
    id && scrollToElement(id)
  }

  useEffect(() => {
    showButton();
  }, []);

  window.addEventListener('resize', showButton);

  return (
    <>
      <nav className='navbar'>
        <div className='navbar-container'>
          <div className='navbar-logo' onClick={() => onLinkClick('#home')}>
            <img src="/images/nav-brand.png" alt="brand logo" />
          </div>
          <div className='menu-icon' onClick={handleClick}>
            {click ? (
              <IoCloseSharp color='white' />
              
            ) : (
              <IoMenuSharp color='white' />
            )}
          </div>
          <ul className={click ? 'nav-menu active' : 'nav-menu'}>
            <li className='nav-item'>
              <div className='nav-links' onClick={() => onLinkClick('#home')}>
                Home
              </div>
            </li>
            <li className='nav-item'>
              <div
                className='nav-links'
                onClick={() => onLinkClick('#services')}
              >
                Services
              </div>
            </li>
            <li className='nav-item'>
              <div
                className='nav-links'
                onClick={() => onLinkClick('#gallery')}
              >
                Gallery
              </div>
            </li>

            <li className="last-item">
              <div
                className='nav-links-mobile desktop-quote-button'
                onClick={() => onLinkClick('#quote')}
              >
                Get a Quote
              </div>
            </li>
          </ul>
          {button && <Button onClick={() => onLinkClick('#quote')} buttonStyle='btn--outline' customClassName='desktop-quote-button'>Get a Quote</Button>}
        </div>
      </nav>
    </>
  );
}

export default Navbar;
