import React, { useState } from 'react';
import '../App.css';
import { Button } from './Button';
import './HeroSection.css';
import Colorful from '@uiw/react-color-colorful';
import { hsvaToHex } from '@uiw/color-convert';

function HeroSection() {
  const [backgroundColor, setBackgroundColor] = useState({ h: 0, s: 0, v: 68, a: 1 })

  const scrollToElement = (id) => {
    const element = document.querySelector(id);
    window.scrollTo({
      top: element.offsetTop - 80,
      behavior: "smooth"
    });
  }

  return (
    <div id="home" className='hero-background' style={{ background: `url('/images/hero-background.png') center center/cover no-repeat, ${hsvaToHex(backgroundColor)}`}}>
      <div className='hero-container'>
        <div className='hero-text-container'>
          <h1>Turning Houses into Homes with Colour.</h1>
          <p>What are you waiting for?</p>
          <div className='hero-btns'>
            <Button
              className='btns'
              buttonStyle='btn--primary'
              buttonSize='btn--large'
              onClick={() => scrollToElement('#quote')}
            >
              Get a Quote
            </Button>
          </div>
        </div>
        <Colorful
          color={backgroundColor}
          onChange={(color) => {
            setBackgroundColor(color.hsva);
          }}
          disableAlpha
          className='color-picker'
        />
      </div>
    </div>
  );
}

export default HeroSection;
