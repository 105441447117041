import React, { useState } from 'react'
import './Gallery.css';
import '../App.css';
import GalleryItem from './GalleryItem';

const IMAGES = [
  '/images/test1.jpg',
  '/images/test2.jpg',
  '/images/test3.jpg',
  '/images/test4.jpg',
  '/images/test5.jpg',
  '/images/test6.jpg',
]

const Gallery = () => {
  const [selectedImageIndex, setSelectedImageIndex] = useState(null);

  const openModal = (index) => {
    setSelectedImageIndex(index);
  };

  const closeModal = () => {
    setSelectedImageIndex(null);
  };

  const nextImage = (e) => {
    e.stopPropagation()
    setSelectedImageIndex((prevIndex) =>
      prevIndex === IMAGES.length - 1 ? 0 : prevIndex + 1
    );
  };

  const prevImage = (e) => {
    e.stopPropagation()
    setSelectedImageIndex((prevIndex) =>
      prevIndex === 0 ? IMAGES.length - 1 : prevIndex - 1
    );
  };

  return (
    <div id="gallery" className='gallery'>
      <h1>Gallery</h1>
      <div className='gallery__container'>
        <div className='gallery__wrapper'>
          <ul className='gallery__items'>
            {IMAGES.map((image, index) => (
              <div onClick={() => openModal(index)} key={index}>
                <GalleryItem href={image} />
              </div>
            ))}
          </ul>
        </div>
      </div>
      {selectedImageIndex !== null && (
        <div className="modal-overlay" onClick={closeModal}>
          <button className='gallery-btn modal-left' onClick={(e) => prevImage(e)}>&lt;</button>
          <div className="modal">
            <img src={IMAGES[selectedImageIndex]} alt={selectedImageIndex} />
          </div>
          <button className='gallery-btn modal-right' onClick={(e) => nextImage(e)}>&gt;</button>
        </div>
      )}
    </div>
  )
}

export default Gallery
